.btn button{
  color: white;
  font-size: 1rem;
  font-weight: 700;
  background-color: red;
  border: none;
  padding: 10px 15px;
  margin: 10px 20px;
  border-radius: 10px;
}
.btn h1{
  color: red;
  padding: 10px 15px;
  margin: 10px 20px;
}

/* 
body{
  background-color: deeppink;

} */

  .form {
    position: relative;
margin-top: 10%;
padding: 10px 15px;
}


.form-group a{
  list-style: none;
  text-decoration: none;
  color:  deeppink;


}
.form-group {

  margin-left:10%;
  margin-right:10%

}
.form-group .Inputs{
  margin-top: 5rem ;

}

.login {
  margin-left: 10%;
  margin-right: 10%;
}

.forget-password a{
  text-decoration: none;
  list-style: none;
  color: deeppink;
}

.form-buttons{
  position: absolute;
  align-items: center;
  z-index: 1;
  margin-top: 6rem;
  padding: 5px 10px;
/* margin-bottom: 1rem;
margin-top: 11rem; */
}

.form-buttons a{
 list-style: none;
 text-decoration: none;
 color: white;
 display: flex;
 align-items: center;
 justify-content: center;
 

}
.signup-btn{

display: flex;
/* float: right; */
margin-right: 2.5rem;
padding: 6px 45px;
color: white;
border-radius: 5px ;
border: none;
background: linear-gradient(to right, #a24dff,#ff6ec4);
}
.login-btn{
  
  display: flex;
align-items: center;
justify-content: center;
margin-right: .5rem;
margin-left:40.5rem ;
padding: 6px 45px;
border-radius: 5px ;
border: none;
color: white;
background: linear-gradient(to right, #a24dff,#ff6ec4);
  }

